section.section-text {
  text-align: center;
  color: black;
  /*padding-top: 16vh;*/
  /*padding-bottom: 16vh;*/
}

section.section-text .limita{
  max-width: 900px;
  margin: 0 auto;
}
