section.section-contact-us {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

label {
  display: block;
}

textarea {
  min-height: 205px;
}

button {
  margin: 0;
}

input[type=checkbox] {
  margin-right: 5px;
  margin-left: 0px;
}

select {
	border: 0px;
	background-color: white;
	-webkit-appearance: none;
	-moz-appearance: none;
  padding: .375rem .75rem;
  border-radius: 5px;
	border: 1px solid #EEEEEE;
	display: flex;
	flex: 1;
}

.select-wrapper {
	position: relative;
}

.select-wrapper:after {
  content: "\e901";
  font-family: 'icomoon' !important;
  color: black;
  right: 10px;
  top: -11px;
  height: 26px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
  font-size: 20px;
}

.checkbox label{
  margin-bottom: 0;
  margin-left: 20px;
}

.order{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.order-reverse{
  flex-direction: row-reverse
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

h2{
  padding-bottom: 5vh;
}

.order img{
  max-width: 60%;
  max-height: 60vh;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.order .text{
  flex: 1;
  max-width: 40%;
  text-align: left;
  padding-left: 5%;
}

.order-reverse .text{
  padding-left: 0;
  padding-right: 5%;
}

section.section-contact-us h2 {
  padding-bottom: 1vh;
  text-align: center;
}

section.section-contact-us p {
  text-align: center;
}

section.section-contact-us a:link, section.section-contact-us a:visited {
  color: #333333;
}

section.section-contact-us a:hover, section.section-contact-us a:active {
  color: #f71d26;
  text-decoration: none;
}

section.section-contact-us .btn-primary {
  background-color: #f71d26;
  color: white;
  outline: 0;
  border: 1px solid #f61e23;
}

section.section-contact-us .btn-secondary {
  background-color: #AAAAAA;
  color: white;
  outline: 0;
  border: 1px solid #AAAAAA;
  margin-left: 5px;
}

/*
section.section-contact-us .btn-primary:hover {
  border: 1px solid #f61e23;
  color: #f61e23;
  background-color: rgba(246, 30, 35, 0.1);
}

section.section-contact-us .btn-secondary:hover {
  background-color: #888888;
  color: white;
  outline: 0;
  border: 1px solid #AAAAAA;
  margin-left: 5px;
}
*/

section.section-contact-us .form-group {
  padding-left: 0;
}

section.section-contact-us p {
  padding-bottom: 20px;
}
