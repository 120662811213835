.modal h4 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.modal h5 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.modal ul {
  list-style: inherit;
  margin-left: 20px !important;
}

.modal .modal-dialog {
  max-width: 750px;
}
.modal .modal-body {
  padding: 1.5rem;
}