section.section-responsive {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.responsive {
  margin: 0 auto;
  display: block;
}
