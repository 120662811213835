@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url('../fonts/AvenirLTStd-Roman.eot');
  src: url('../fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
  url('../fonts/AvenirLTStd-Roman.woff') format('woff'),
  url('../fonts/AvenirLTStd-Roman.ttf') format('truetype'),
  url('../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: url('../fonts/AvenirLTStd-Medium.eot');
  src: url('../fonts/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirLTStd-Medium.woff2') format('woff2'),
  url('../fonts/AvenirLTStd-Medium.woff') format('woff'),
  url('../fonts/AvenirLTStd-Medium.ttf') format('truetype'),
  url('../fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url('../fonts/AvenirLTStd-Black.eot');
  src: url('../fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirLTStd-Black.woff2') format('woff2'),
  url('../fonts/AvenirLTStd-Black.woff') format('woff'),
  url('../fonts/AvenirLTStd-Black.ttf') format('truetype'),
  url('../fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}


/* Fix iPhone letter-spacing */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'AvenirLTStd-Roman';
    src: url('../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  }

  @font-face {
    font-family: 'AvenirLTStd-Medium';
    src: url('../fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
  }

  @font-face {
    font-family: 'AvenirLTStd-Black';
    src: url('../fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
  }
}

.App {
  font-family: 'AvenirLTStd-Roman';
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App .head {
  text-transform: uppercase;
}

ul{
  list-style: none;
}

ul li, ul{
  margin: 0;
  padding: 0;
}

p {

}

h1, h2, h3{
  text-align: left;
  font-family: 'AvenirLTStd-Medium';
}

section{
  height: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
}

section h2{
  padding-bottom: 1.5rem;
  font-size: 1.9rem;
  text-align: left;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.m-card {
  box-shadow: 0 0 10px rgba(0,0,0,.1)
}

.btn-primary {
  border: 1px solid #f61e23;
  color: white;
  background: #f61e23;
}

.btn-primary:hover {
  border: 1px solid #f61e23;
  color: white;
  background: #f61e23;
}

/*
.btn-primary:hover, .btn-primary:active {
  border: 1px solid #333;
  background: #333;
  color: white;
}
*/

.btn-secondary {
  border: 1px solid #5a6268;
  color: #5a6268;
  background: white;
}

/*
.btn-secondary:hover, .btn-secondary:active {
  border: 1px solid #5a6268;
  background: #5a6268;
  color: white;
}
*/

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?afo0h0');
  src:  url('../fonts/icomoon.eot?afo0h0#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?afo0h0') format('truetype'),
  url('../fonts/icomoon.woff?afo0h0') format('woff'),
  url('../fonts/icomoon.svg?afo0h0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_drop_down:before {
  content: "\e900";
}
.icon-keyboard_arrow_down:before {
  content: "\e901";
}

strong {
  font-family: 'AvenirLTStd-Black';
}

/* MOBILE */

@media (max-width: 991.98px) {
  section {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  section h2 {
    text-align: left;
  }

  section img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

a {
  color: #f61e23;
}

a:hover {
  color: #f61e23;
}