footer {
  background: #03050C;
  color: #D8DFE9;
}

footer .credits{
  text-align: center;
}

footer .credits img {
  max-height: 100px;
  margin: 0 auto;
  display: block;
  border-radius: 15px;
}

footer .credits .other-info{
  font-size: 10pt;
}

footer .credits .bold {
  font-weight: bold;
}

footer a:link, footer a:visited {
  font-family: 'AvenirLTStd-Roman';
  color: #f71d26;
}

footer a:hover, footer a:active {
  font-family: 'AvenirLTStd-Roman';
  color: #f71d26;
  text-decoration: none;
}

footer .credits img {
  max-height: 100px;
  margin: 0 auto;
  display: block;
}

footer .credits p {
  text-align: center;
}

footer .credits p.legal {
  padding-top: 30px;
  font-weight: bold;
  font-size: 12pt;
  line-height: 11px;
}

footer .credits p.piva {
  font-size: 12pt;
  line-height: 2px;
}

footer .credits p.link {
  font-size: 12pt;
  font-weight: bold;
  line-height: 48px;
}

@media (max-width: 767.98px) {
  footer .credits p.legal {
    line-height: 25px;
  }

  footer .credits p.piva {
    line-height: 25px;
  }
}