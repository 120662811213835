.simple-photo img {
	max-height: 100px;
	display: block;
	margin: 0 auto;
}

.simple-photo a {
	color: black;
}

.simple-photo p {
	text-align: center;
}