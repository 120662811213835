.project-name-logo{
  padding: .6rem 2rem;
  font-size: 2.5rem;
  color: white;
  background-color: #f61e23;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.claim-content .main-content{
  margin: 0 auto;
}

.claim-content .main-content h1{
  margin-bottom: 2rem;
  text-rendering: optimizeSpeed;
}

.claim-content .head{
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.2rem;
  color: white;
  line-height: .5rem;
}

.claim-content .head a span.claim-head{
  color: #999999;
  font-size: .9rem;
  position: absolute;
  top: 25px;
  left: 44px;
}

.claim-content .head a:hover span {
  color: #f61e23;
  text-decoration: none;
}

.claim-content .head a .icon-atleticame .path1:before {
  content: "\e902";
  color: #999;
}

.claim-content .head a .icon-atleticame .path2:before {
  content: "\e903";
  color: #fff;
  margin-left: -1em;
}

.claim-content .head a:hover .icon-atleticame .path1:before {
  content: "\e902";
  color: #f61e23;
}
a:hover {
  text-decoration: none;
}

.claim-content .btn-container {
  display: block;
}

.claim-content .btn-container .btn {
  min-width: 300px;
}

.claim-content .btn-primary {
  background-color: #f61e23;
  color: white;
  outline: 0;
  border: 1px solid #f61e23;
}

/*
.claim-content .btn-primary:hover, .btn-primary:active {
  background-color: rgba(246, 30, 35, 0.1);
  color: #f61e23;
  outline: 0;
  border: 1px solid #f61e23;
}
*/

.claim-content .btn-secondary {
  border: 1px solid #f61e23;
  color: #f61e23;
  background: white;
}

/*
.claim-content .btn-secondary:hover, .btn-secondary:active {
  border: 1px solid #f61e23;
  color: #f61e23;
  background-color: rgba(246, 30, 35, 0.1);
}
*/

/* MOBILE */

@media (max-width: 991.98px) {
  .claim-content {
    padding-top: 30px;
  }

  .claim-content .btn-container .btn {
    min-width: auto;
    width: 100%;
  }
}
