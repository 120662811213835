.best-plan{
  transform: scale(1.05);
  border: 2px solid rgb(255, 15, 115);
}

.best-plan .card-footer{
  background-color: rgba(255, 15, 75, 0.8)
}

@media(max-width: 992px) {
  .best-plan {
    transform: scale(1);
  }
}

span.on-sale{
  opacity: .7;
  margin-right: .5rem;
  position: relative;
}

span.on-sale::after{
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: 1px solid black;
  transform: rotateZ(20deg);
  opacity: 1;
}
