section.section-revolution {
  height: 100%;
  min-height: 100%;
}

section.section-revolution h2 {
  text-align: center;
}

section.section-revolution p.clubs-name {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  position: relative;
}

section.section-revolution p small {
  font-family: 'AvenirLTStd-Black';
  font-weight: 900;
}

section.section-revolution .img-container {
  height: 80px;
}
